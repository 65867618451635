@use 'mixins';

.Footer {
    background: rgb(var(--lighterInvertedBackground));
    text-align: center;

    [data-theme='br'] & {
        background: rgb(var(--lightBackground));
    }

    h3 {
        margin-top: var(--largePadding);
        margin-bottom: var(--standardPadding);
        white-space: nowrap;
    }

    .spacer {
        margin: var(--largePadding) 0;
    }

    @include mixins.responsive(m, above) {
        padding-top: var(--standardPadding);
        text-align: initial;
    }
}
